import clsx from "clsx"
import { useSession } from "components/auth/useSession"
import { signOut } from "next-auth/react"
import { useEffect, useState } from "react"
import Arrow from "./arrow"
import styles from "./auth.module.scss"
import ForgotPassword from "./forgotPassword"
import Layout from "./layout"
import Modal from "./modal"
import SignIn from "./signIn"
import SignUp from "./signUp"

const decorationLetterName = (name: string) => {
    if (!name) {
        return ''
    }
    if (name.length <= 2) {
        return name
    }
    const splitString = name.split(' ');
    if (splitString.length === 1) {
        return splitString[0].charAt(0);
    }
    if (splitString.length > 1) {
        return splitString[0].charAt(0) + splitString[1].charAt(0);
    }

}
export default function Component({isMobileApp}: {isMobileApp?: boolean}) {
    const { data: session } = useSession(true)
    const [form, setForm] = useState("")
	const [isCMSUser, setCMSUser] = useState(false);

	//chk if user is cms user
	useEffect( () => {
		const isCMSSession = window.sessionStorage.getItem('isCMS');
		if (isCMSSession == "1") {
			setCMSUser(true);
			return;
		}

		if (document.cookie.match(/(word.*?_test_cookie|wp\-)/si)) {
			//test user
			fetch(`/api/auth/cms`, {
				method: "POST",
				headers: {
					"Content-Type": 'application/json'
				}
			})
			.then( r => r.json() )
			.then( u => {
				if (u?.id) {
					setCMSUser(true);
					window.sessionStorage.setItem('isCMS', "1");
				} else {
					setCMSUser(false);
					window.sessionStorage.removeItem('isCMS');
				}
			})
			.catch( () => {
				setCMSUser(false);
				window.sessionStorage.removeItem('isCMS');
			})
		}
	}, [])

    return (
        <>
            {session?.user ? (
                <div className={styles.userImageContainer}>
                    <button className={clsx(styles.userContainer)} >
                        {session.user.image
                            ? <img src={session.user.image} alt={session.user.name!} referrerPolicy="no-referrer" className={styles.user} />
                            : <div className={clsx(styles.user, styles.accountLetter)}>{decorationLetterName(session.user.name ?? "")}</div>
                        }
                        <Arrow />
                    </button>

                    <div className={styles.dropdownContainer}>
                        <div className={styles.dropdown}>
                            <a href="/profile" className={styles.lnks}>Ο λογαριασμός μου </a>
                            <button className={styles.lnks} onClick={() => signOut({ callbackUrl: "/" })}>Έξοδος</button>
                        </div>
                    </div>
                </div >
            ) : <button className={clsx("svgIcon", styles.userContainer)} onClick={() => setForm("signIn")}>
                <svg className={styles.user}> <use xlinkHref="#IconUser"></use> </svg>
            </button>
            }

            <Modal show={!!form} onClose={() => setForm("")}>
                <Layout form={form} setForm={setForm}>
                    {form === 'signIn' && <SignIn setForm={setForm} isMobileApp={isMobileApp}/>}
                    {form === 'signUp' && <SignUp setForm={setForm} />}
                    {form === 'forgotPassword' && <ForgotPassword setForm={setForm} />}
                </Layout>
            </Modal>

			{isCMSUser && (
				<iframe src="https://www.newsbeast.gr/cpml/int/index.html" width={0} height={0} frameBorder={0} style={{opacity: 0, position: 'absolute'}} />
			)}
        </>
    )
}
